import request from '@/plugins/http'

export function setOrderPrintTemplate(param) {
    return request.post('/api/common/setOrderPrintTemplate', param);
}

export function getOrderPrintTemplate() {
    return request.post('/api/common/getOrderPrintTemplate');
}

export function getBuyOrderPrintTemplate() {
    return request.post('/api/common/getBuyOrderPrintTemplate');
}

export function setBuyOrderPrintTemplate(param) {
    return request.post('/api/common/setBuyOrderPrintTemplate', param);
}

