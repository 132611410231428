<template>
  <div class="main-info">
    <div v-show="errorFlag" style="margin:0 auto;width: 200mm;padding-top:10px;">
      <Alert show-icon style="font-size:12px;">
        若销售单需要调整，可以去配置打印模板信息!
        <Icon type="ios-bulb-outline" slot="icon"></Icon>
      </Alert>
    </div>
    <div class="print-button">
      <Button type="info"  @click="downData()" :loading="down_modal_loading">下载</Button>
      <Button type="primary" class="ml-10" @click="printData()" :loading="print_modal_loading">打印</Button>
    </div>
    <div id="a5-print">
      <div class="header">
        <div style="min-width: 6mm;">
          <img v-show="a5Item.logo" style="height:12mm;" :src="a5Item.logo" alt="">
        </div>
        <div class="fs-22 fw-400 align-center">{{a5Item.company_name}}销售单</div>
        <div style="width:12mm;">
          <img v-show="a5Item.wxCode" style="width:12mm;height:12mm;" :src="a5Item.wxCode" alt="">
        </div>
      </div>
      <div class="column-info">
        <div><strong>NO.{{order.no}}</strong></div>
        <div style="display:flex;justify-content: space-between;">
          <span>客户：{{ order.clientName }}<span v-show="order.clientContactPhone">({{order.clientContactPhone}})</span> </span>
          <span>日期：{{order.date_info}}</span>
        </div>
<!--        <div><span>收货地址: {{order.address}}</span></div>-->
      </div>
      <div style="margin-top:5px;">
        <table cellspacing="0" cellpadding="0" border="1">
          <thead>
          <tr>
            <th><span>编号</span></th>
            <th><span>产品</span></th>
            <th><span>数量</span></th>
            <th><span>单价</span></th>
            <th><span>金额</span></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in order.goods_set">
            <td><span>{{item.no}}</span></td>
            <td>
              <span v-if="item.free_flag==1" class="fs-12">[赠]</span>
              <span>{{item.name}}</span>
            </td>
            <td><span>{{item.num}}</span>
            </td>
            <td><span>{{item.price}}</span>
            </td>
            <td><span>{{item.totalPrice}}</span>
            </td>
          </tr>
          <tr>
            <td><span></span>
            </td>
            <td><span>合计</span>
            </td>
            <td><span>{{order.totalNum}}</span>
            </td>
            <td><span></span></td>
            <td><span>{{order.totalCount}}</span></td>
          </tr>
          <tr>
            <td colspan="7" style="text-align: right;padding-left:10px;">
              <span class="fs-12" v-show="order.discount_amount>0">优惠: {{order.discount_amount}}</span>
              <span class="ml-10 fs-12" v-show="order.logistics_pay>0">物流运费: {{order.logistics_pay}}</span>
              <span class="ml-10">合计金额: <strong>{{ order.capital_amount }}</strong>（<strong>{{order.totalPay}}</strong>）</span>
            </td>
          </tr>
          <tr>
            <td colspan="7" style="text-align: left;padding-left:10px;">
              <span>备注：{{order.remark}}</span>
            </td>
          </tr>
          <tr>
            <td colspan="7" style="text-align: left;padding-left:10px;">
              <span>*尊敬的客户：货物的数量、品种、规格，请当面点清*</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="column-info">
        <div style="display:flex;justify-content: space-between;">
          <span v-show="a5Item.contact_phone">订货电话：{{a5Item.contact_phone}}</span>
          <span>制单人：{{ order.operator_name }}</span>
        </div>
        <div style="display:flex;justify-content: space-between;">
          <span v-show="a5Item.contact_address">订货地址: {{a5Item.contact_address}}</span>
          <span>收货人(签字)：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <div style="font-size:10px;">{{a5Item.company_introduce}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';
import NP from 'number-precision';
import * as helper from '@/utils/helper';
import printJS from 'print-js'


import {getOrderPrintTemplate} from "@/api/setting/orderPrintSetting";


export default {
  name: "a5Print",
  data() {
    return {
      errorFlag: false,
      print_modal_loading: false,
      down_modal_loading: false,

      a5Item: {
        logo: '',
        wxCode: '',
        contact_address: '',
        company_name: '',
        contact_phone: '',
        company_introduce: '',
      },
      order: {
        capital_amount: '',
        clientName: '',
        clientContactPhone: '',
        operator_name: '',
        no: '',
        date_info: '',
        address: '',
        goods_set: [],
        totalNum: 0,
        totalPay: 0,
        discount_amount: 0,
        logistics_pay: 0,
        totalCount: 0,
        remark: '',
      }
    }
  },
  mounted() {
    const {no, printType} = this.$route.query

    switch (printType) {
      case 'sale':
        this.getSaleData(no);
    }
    this.getOrderPrintTemplate();
  },
  methods: {
    getOrderPrintTemplate() {
      getOrderPrintTemplate().then(res => {
        if (res.data.results.length > 0) {
          const content = JSON.parse(res.data.results[0].content);
          this.a5Item.contact_address = content.contact_address;
          this.a5Item.company_name = content.company_name;
          this.a5Item.contact_phone = content.contact_phone;
          this.a5Item.company_introduce = content.company_introduce;

          if (content.logo) {
            // this.a5Item.logo = content.logo;
            this.getBase64Image(content.logo)
                .then(base64Image => {
                  // 成功获取到base64编码
                  this.a5Item.logo = base64Image;
                }).catch(error => {
                  // 获取base64编码失败，处理错误信息
                });
          }

          if (content.wx_code) {
            this.getBase64Image(content.wx_code)
                .then(base64Image => {
                  // 成功获取到base64编码
                  this.a5Item.wxCode = base64Image;
                }).catch(error => {
              // 获取base64编码失败，处理错误信息
            });
          }

        } else {
          this.errorFlag = true;
        }
      })
    },

    downData() {
      this.down_modal_loading = true;
      // 获取要转换为PDF的HTML元素
      const element = document.getElementById('a5-print');

      const options = {
        margin: [0, 0, 0, 0],
        image: {type: 'png', quality: 0.98},
        html2canvas: {scale: 4},
        jsPDF: {unit: 'mm', format: 'a5', orientation: 'landscape'},
      };

      // 将HTML转换为PDF
      html2pdf().set(options).from(element).save('销售单'+this.order.no+'.pdf').then(res => {
        this.down_modal_loading = false;
      });
    },
    printData() {
      this.print_modal_loading = true;
      // 获取要转换为PDF的HTML元素
      const element = document.getElementById('a5-print');

      const options = {
        margin: [0, 0, 0, 0],
        image: {type: 'png', quality: 0.98},
        html2canvas: {scale: 4},
        jsPDF: {unit: 'mm', format: 'a5', orientation: 'landscape'},
      };

      // 将HTML转换为PDF
      html2pdf().set(options).from(element).outputPdf('blob').then(blob => {
        printJS(window.URL.createObjectURL(new Blob([blob],{type: 'application/pdf'})));
        setTimeout(() => {this.print_modal_loading = false;}, 3000 );
      });
    },
    getSaleData(no) {
      this.$axios.get('/api/sale/getSaleOrderById?id=' + no).then((res) => {
        if (res.data.results) {
          const result = res.data.results
          this.order.no = result.sale_no;
          this.order.date_info = result.sale_date.substring(0, 10);
          this.order.totalNum = parseFloat(result.goods_num);
          this.order.totalPay = parseFloat(result.goods_pay);
          this.order.remark = result.remark;
          this.order.discount_amount = parseFloat(result.discount_amount);
          this.order.logistics_pay = parseFloat(result.logistics_pay);
          this.order.totalCount = NP.plus(result.goods_pay, result.discount_amount, result.logistics_pay);
          this.order.address = result.address;
          this.order.operator_name = result.operator_name;
          this.order.clientName = result.clientInfo.client_name;
          this.order.clientContactPhone = result.clientInfo.contact_phone;
          this.order.capital_amount = helper.amountOfCapital(this.order.totalPay);

          this.order.goods_set = [];
          let total = result.goods_setted.length;
          for (let i=0; i<total; i++) {
            let tmp = {};
            if (i+1 <= result.goods_setted.length) {
              tmp.no = i+1;
              tmp.name = result.goods_setted[i].part_name;
              tmp.num = parseFloat(result.goods_setted[i].sale_num);
              tmp.price = parseFloat(result.goods_setted[i].sale_price);
              tmp.free_flag = result.goods_setted[i].free_flag;
              // tmp.specification = result.goods_setted[i].specification;
              tmp.totalPrice = NP.times(result.goods_setted[i].sale_num, result.goods_setted[i].sale_price);
            }

            this.order.goods_set.push(tmp);
          }
        } else {
          this.$Message.error('订单ID异常，请留意！！！');
        }
      });
    },
    getBase64Image(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url+"?timeStamp="+new Date();
        img.onload = function () {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(this, 0, 0);
          let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase(); // 获取到图片的格式
          const dataURL = canvas.toDataURL("image/" + ext); // 得到base64 编码的 dataURL
          resolve(dataURL);
        };
        img.onerror = function () {
          reject(new Error("图片加载失败"));
        };
      });
    },
  }
}
</script>

<style scoped>
.main-info {
  background-color: #f8f8f9;
  height: calc(100vh - 50px);
}

.print-button {
  position: absolute;
  right: 80px;
  padding-top: 20px;
}

#a5-print {
  width: 206mm;
  height: 146mm;
  margin: 0 auto;
  background-color: #fff;
  padding: 3mm 2mm 2mm 2mm;
}

#a5-print .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 12mm;
}

#a5-print table {
  border-collapse: collapse; /* 合并边框 */
  width: 100%;
  border-color: #ccc;
  border-width: 0.75pt;
}


#a5-print table td, #a5-print table th {
  height: 24px;
  text-align: center;
}


</style>
